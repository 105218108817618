import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import {formatDateStr} from '../../Utils/TimeUtil';

function formatReleaseDateStr(str) {
  return (str || '').slice(0, 10);
}

function AnnouncementDetail(props) {
  const params = qs.parse(props.location.search);
  const {id} = params;
  const [record, setRecord] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setRecord(await JStorage.fetchOneDocument('News', {id}));
        AppActions.setLoading(false);
      } catch (err) {
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [id]);

  return (
    <Wrapper>
      {record && (
        <div className="container">
          <div className="title">
            <h2>{record.Title}</h2>
            <span>
              {(record.Release_date &&
                formatReleaseDateStr(record.Release_date)) ||
                formatDateStr(new Date(record.created))}
            </span>
          </div>
          <div className="divider" />
          <div className="content">
            {record.Detail_Content && (
              <div dangerouslySetInnerHTML={{__html: record.Detail_Content}} />
            )}

            {record.richtext?.content && (
              <RichTextPreview
                key={record.id}
                content={record.richtext.content}
              />
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  & > .container {
    margin: 0 auto;
    max-width: var(--containerMaxWidth900px);
    width: 100%;
    padding: 20px 40px;

    & > div.title {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    & > div.divider {
      background-color: var(--primaryColor);
      width: 100%;
      height: 8px;
    }

    & > div.content {
      padding-top: 40px;
      font-size: 1rem;
    }
  }
`;

export default withPageEntry(AnnouncementDetail);
